import React from "react"

import styled from "@emotion/styled"

import Layout from "../components/mainLayout"
import SEO from "../components/seo"
import Logo from "../svg/Logo"

interface Props {
  data: {
    allMarkdownRemark: any
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
}

const MainSection = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const LogoStyle = styled.div`
  width: 100%;
  max-width: 200px;
`
const Body = styled.div`
  text-align: center;
  h1,
  h2 {
    margin: 0;
  }
`

const H1 = styled.h1`
  margin: 0;
  font-weight: 400;
`

const H2 = styled.h2`
  margin: 10px !important;
  font-size: 1.2em;
  font-weight: 100;
`
const BlogIndex = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <MainSection>
        <LogoStyle>
          <Logo />
        </LogoStyle>
        <Body>
          <H1>Daniel Rodriguez</H1>
          <H2>Senior Full Stack Engineer</H2>
          <a href="mailto:daniel@danielrodriguez.io" target="_blank">
            daniel@danielrodriguez.io
          </a>
        </Body>
      </MainSection>
    </Layout>
  )
}

export default BlogIndex
