import React from "react"

export default () => (
  <svg
    version="1.1"
    id="Layer_Vic"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 3793.7 2721.9"
  >
    <g>
      <path
        className="st0"
        d="M3359.7,1232.9l-268.2,244.6c-59-64.7-139.6-101.4-227.1-103.4c-87.5-2-169.8,31-231.7,92.8L1776,2323.6
c-170.8,170.9-395.3,256.3-619.8,256.3c-224.5,0-448.9-85.4-619.8-256.3c-165.6-165.5-256.7-385.6-256.7-619.8
c0-234.1,91.2-454.2,256.7-619.8c313.2-313.2,806.6-339.4,1149.9-78.3V108.9h363v1308.4l-390,390c6.9-33.7,10.4-68.3,10.4-103.5
c0-137.2-53.4-266.1-150.4-363.1c-50.1-50.1-108-87.6-169.8-112.6c-61.8-25-127.6-37.5-193.3-37.5c-131.5,0-263,50.1-363.1,150.2
c-97,97-150.4,225.9-150.4,363.1c0,137.2,53.4,266.1,150.4,363.1c200.2,200.2,526,200.2,726.2,0l856.7-856.7
c15.4-15.4,31.5-30,48-43.8c125.9-104.4,283-159.2,448.7-155.3c0.8,0,1.5,0,2.3,0.1C3061.7,1016.2,3233.8,1094.8,3359.7,1232.9z"
      />
    </g>
  </svg>
)
